import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'assets/styles/pages/sidebar.scss';
import 'assets/styles/pages/dashboardstatus.scss';
import eyeicon from 'assets/images/admin/visibility.png';
import linkicon from 'assets/images/admin/link.png';
import AlertDismissible from 'components/admin/alertpops';
import { Link } from 'gatsby';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      className='custom-species-popup'
      centered>
      <Modal.Header closeButton>
        <AlertDismissible />
      </Modal.Header>
      <Modal.Body>
        <h3 className='sub-heading-title' style={{ marginLeft: '10px' }}>
          {' '}
          Donated Project : GREEN KANHA{' '}
        </h3>
        <div className='register-plantation'>
          <div className='container'>
            <div className='row'>
              <form>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='fc-wrapper'>
                      <label htmlFor='name' className='form-label'>
                        Mapping Project
                      </label>
                      <input
                        type='text'
                        name='speciesname'
                        className='form-control '
                        id='speciesname'
                        placeholder='Green Kanha Initiative (1000 trees)'
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="whiteColor" style={{ color: 'black' }}>Close</Button>
        <Link to='/admin/donorlist' className='btn btn-approve save-width posi-close-top'>
          Submit
        </Link>

      </Modal.Footer>
    </Modal>
  );
}

function Donorpopuppage() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      {/* <button className='btn btn-white-button fl-right' >
        + Add Species
      </button> */}

      <a className='circle-view' onClick={() => setModalShow(true)}>
        {' '}
        <img className='img-fluid eye-max-w' src={linkicon} alt='bellicon' />{' '}
      </a>

      <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Donorpopuppage;
