import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import 'assets/styles/pages/sidebar.scss';
import 'assets/styles/pages/dashboardstatus.scss';

function AlertDismissible() {
  const [show, setShow] = useState(true);

  return (
    <>
      <Alert className='text-center alert-green' show={show}>
        <div>Species Mapped Successfully!</div>
      </Alert>
    </>
  );
}

export default AlertDismissible;

